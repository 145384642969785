import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    singleDocFolder: 'uploads/single_documentaries',
    seriesDocFolder: 'uploads/series_documentaries',
    singleFaPortFolder: 'uploads/single_fashion_portraits',
    seriesFaPortFolder: 'uploads/series_fashion_portraits',
    about: [],
    awards: [],
    singleDoc: [{
        id: "c961ca87-29df-11eb-8979-00155d4e2c70",
        img: "2a7e644a-3e3e-4426-91fc-b0e557d55c82.jpg"
      },
      {
        id: "c961f621-29df-11eb-8979-00155d4e2c70",
        img: "4beba677-658e-4103-a964-278428db5a18.jpg"
      },
      {
        id: "c9621e63-29df-11eb-8979-00155d4e2c70",
        img: "5cc1ce85-5685-4fff-9d83-4fdcb3ea8ee8.jpg"
      },
      {
        id: "c962465a-29df-11eb-8979-00155d4e2c70",
        img: "7c90735a-b0b7-46d4-8c92-492890af315f.jpg"
      }
    ],
    seriesDoc: [{
        id: "810b78ce-2a54-11eb-a5ea-00155d4e2c70",
        imgs: [{
            img: "c5d940ce-ab24-4fe8-b893-a860247490f0.jpg",
            order: 1,
            active: true
          },
          {
            img: "cdd610b0-e853-4373-8469-d2955e411589.jpg",
            order: 2,
            active: true
          },
          {
            img: "f780e835-f0df-4596-9ebc-5736c7ef5d3e.jpg",
            order: 3,
            active: true
          }
        ],
        title: "Nail Lagos",
        description: "Nail cleaning and beautification."
      },
      {
        id: "810bbdc9-2a54-11eb-a5ea-00155d4e2c70",
        imgs: [{
            img: "6d8feafd-d773-491f-9858-e685075ceab8.jpg",
            order: 1,
            active: true
          },
          {
            img: "2b6a1145-bcc1-4739-b335-079e41002476.jpg",
            order: 2,
            active: true
          },
          {
            img: "b46f6946-3371-4a9c-b7b5-becbba930e52.jpg",
            order: 3,
            active: true
          },
          {
            img: "336b3506-712e-41ab-b272-9cbe95fc8b40.jpg",
            order: 4,
            active: true
          },
          {
            img: "460fc042-4ef3-4174-ae14-c5e20534a5a3.jpg",
            order: 5,
            active: true
          },
          {
            img: "ddaf3940-17cc-4c7e-ba20-9c5be1f39976.jpg",
            order: 6,
            active: true
          },
          {
            img: "d5ffde3a-b5f5-43ae-a566-1f8ba686a9dd.jpg",
            order: 7,
            active: true
          },
          {
            img: "7011027c-cf81-483d-95cd-3321fb8bdd78.jpg",
            order: 8,
            active: true
          }
        ],
        title: "Solemn Road",
        description: "The Solemn Road."
      }
    ],
    singleFaPort: [{
        id: "27baf2a5-2a56-11eb-a5ea-00155d4e2c70",
        img: "870cead4-6367-4b1b-be0d-8873fdbf74d2.jpg"
      },
      {
        id: "27bb230b-2a56-11eb-a5ea-00155d4e2c70",
        img: "70120114-8114-4905-b3c9-1557ba627293.jpg"
      }
    ],
    seriesFaPort: [{
        id: "c2b55d9d-2a58-11eb-a5ea-00155d4e2c70",
        imgs: [{
            img: "0a114b26-2dfd-428b-9821-b731bdd29703.jpg",
            order: 1,
            active: true
          },
          {
            img: "57122103-7cc8-4812-b10f-533337a0656f.jpg",
            order: 2,
            active: true
          },
          {
            img: "0fabb8c0-be92-4a48-b516-174389cf3b60.jpg",
            order: 3,
            active: true
          },
          {
            img: "1268b54d-e53c-4384-affa-567debe4cc8b.jpg",
            order: 4,
            active: true
          },
          {
            img: "e7c5b3c3-fee1-450c-91ea-40c935085cc3.jpg",
            order: 5,
            active: true
          },
          {
            img: "f734b082-8728-44ba-957b-536078be93e2.jpg",
            order: 6,
            active: true
          }
        ],
        title: "GTFashion Weekend 2019",
        description: "The Guaranty Trust Bank Fashion Weekend, 2019."
      },
      {
        id: "c2b58e84-2a58-11eb-a5ea-00155d4e2c70",
        imgs: [{
            img: "25b0e5ca-9709-4211-95c5-67063817d5a5.jpg",
            order: 1,
            active: true
          },
          {
            img: "011be926-3461-44e2-8527-3ba430eb9086.jpg",
            order: 2,
            active: true
          },
          {
            img: "d13819b6-c97d-4d3b-be91-6e41cc8a16e5.jpg",
            order: 3,
            active: true
          }
        ],
        title: "Tramposo Collection",
        description: "The Tramposo Collection"
      }
    ],
  },
  mutations: {
    about: (state, payload) => (state.about = [...payload]),
    awards: (state, payload) => (state.awards = [...payload]),
    singleDoc: (state, payload) => (state.singleDoc = [...payload]),
    seriesDoc: (state, payload) => (state.seriesDoc = [...payload]),
    singleFaPort: (state, payload) => (state.singleFaPort = [...payload]),
    seriesFaPort: (state, payload) => (state.seriesFaPort = [...payload]),
  },
  actions: {
    setAbout: ({ commit }, payload) => commit('about', payload),
    setAwards: ({ commit }, payload) => commit('awards', payload),
    setSingleDoc: ({ commit }, payload) => commit('singleDoc', payload),
    setSeriesDoc: ({ commit }, payload) => commit('seriesDoc', payload),
    setSingleFaPort: ({ commit }, payload) => commit('singleFaPort', payload),
    setSeriesFaPort: ({ commit }, payload) => commit('seriesFaPort', payload),
  },
  getters: {
    activeAbout: state => state.about[0],
    seriesTitle: state => {
      const title = { doc: [], faPort: [] };
      state.seriesDoc.forEach(s => (title.doc.push({ id: s.id, title: s.title })));
      state.seriesFaPort.forEach(s => (title.faPort.push({ id: s.id, title: s.title })));
      return title;
    }
  },
  modules: {}
});