<template>
  <div>
    <v-list-item :to="{name: 'home'}" @click="hideDrawer" class="text-uppercase text-center">
        
        <v-list-item-content>
          <v-list-item-title><strong>Olawale Adekola</strong></v-list-item-title>
          <v-list-item-subtitle>
            Visual Storyteller
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Film Maker
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :to="{name: 'about'}" @click="hideDrawer">
        About
      </v-list-item>
      <v-list-item :to="{name: 'awards'}" @click="hideDrawer">
        Awards
      </v-list-item>

      <v-list-group >
        <template v-slot:activator>
          <v-list-item-title>Documentary</v-list-item-title>
        </template>
        <v-list-item class="ml-3" @click="hideDrawer" :to="{name: 'singles', params: {type: 'documentaries'}}"> 
          Singles
        </v-list-item>
        <v-list-item class="ml-3" @click="hideDrawer" :to="{name: 'series', params: {type: 'documentaries'}}"> 
          Series
        </v-list-item>        
      </v-list-group>

      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>Fashion & Portraits</v-list-item-title>
        </template>
        <v-list-item class="ml-3" @click="hideDrawer" :to="{name: 'singles', params: {type: 'fashion-portraits'}}"> 
          Singles
        </v-list-item>
        <v-list-item class="ml-3" @click="hideDrawer" :to="{name: 'series', params: {type: 'fashion-portraits'}}"> 
          Series
        </v-list-item>
      </v-list-group>

      <v-list-item  @click="hideDrawer">
        Contact
      </v-list-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "NavDrawer",

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(['seriesTitle']),
  },

  props: {
    hideDrawer: Function,
  }
};
</script>
