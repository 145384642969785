<template>
  <div class="ma-5">
    
    <!-- < justify="center"> -->
      <v-row dense align="center"  align-content="center">
        <v-col cols="12" sm="6" v-for="img in singleDoc" :key="img.id">
        <v-img contain :src="`${singleDocFolder}/${img.img}`"></v-img>
        </v-col>
        <v-col cols="12" sm="6" v-for="img in singleFaPort" :key="img.id">
        <v-img contain :src="`${singleFaPortFolder}/${img.img}`"></v-img>
        </v-col>
      </v-row>
  </div>
</template>

<script>
// @ is an alias to /src
// import axios from "axios";
import { mapState } from 'vuex';

// const APIURL = ''

export default {
  name: "Home",
  data: () => ({
    //
  }),
  computed: {
    ...mapState(['singleDocFolder', 'singleFaPortFolder', 'singleDoc', 'singleFaPort'])
  },
  created(){
    // axios
    //   .all([
    //     axios.get('/api/about.php'),
    //   ])
    //   .then(
    //     axios.spread((get) => {
    //       console.log(get.data)     
    //     })
    //   )
    //   .catch(err => console.error(err));
  }
};
</script>
