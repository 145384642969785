import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
let store = { user: null }

Vue.use(VueRouter);

const routes = [{
    path: "/i",
    alias: "/",
    name: "home",
    component: Home,
    props: true
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import ( /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import ( /* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/awards",
    name: "awards",
    component: () =>
      import ( /* webpackChunkName: "awards" */ "../views/Awards.vue"),
  },
  {
    path: "/singles/:type",
    name: "singles",
    props: true,
    component: () =>
      import ( /* webpackChunkName: "singles" */ "../views/Singles.vue"),
  },
  {
    path: "/series/:type",
    name: "series",
    props: true,
    component: () =>
      import ( /* webpackChunkName: "series" */ "../views/Series.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import ( /* webpackChunkName: "admin" */ "../views/Admin.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import ( /* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/404",
    alias: "*",
    name: "notFound",
    component: () =>
      import ( /* webpackChunkName: "notFound" */ "../views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      const position = {};
      // scroll to anchor by returning the selector
      if (to.hash) {
        position.selector = to.hash;
        if (document.querySelector(to.hash)) {
          return position;
        }

        return false;
      }
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.user) {
      next({ name: "login", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;