<template>
  <v-app>
    <v-app-bar
      :app="mobile"
      color="white"
      elevation="1"
      v-show="mobile"
      clipped-right
    >      
      <v-toolbar-title class="text-uppercase">Olawale Adekola</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
      <v-footer>
      <v-bottom-navigation
      v-model="social"
      color="indigo"
      class="mt-2"
    >
        <v-btn value="instagram" href="https://www.instagram.com/waleadekola_/" target="_blank">
          <span>Instagram</span>

          <v-icon>mdi-instagram</v-icon>
        </v-btn>

        <v-btn value="facebook" href="https://www.facebook.com/adekolajsp" target="_blank">
          <span>Facebook</span>

          <v-icon>mdi-facebook</v-icon>
        </v-btn>

        <v-btn value="twitter" href="https://twitter.com/waleadekola_/" target="_blank">
          <span>Twitter</span>

          <v-icon>mdi-twitter</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="text-center mt-5 mx-auto font-weight-light">
        Copyright &copy; {{ new Date().getFullYear() }} Olawale Adekola. All rights reserved.
      </div>
      </v-footer>
    </v-main>

    <v-navigation-drawer app right v-model="drawer" hide-overlay :clipped="mobile" stateless width="250" class="text-left mt-5">
      <nav-drawer :hideDrawer="hideDrawer"></nav-drawer>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import NavDrawer from "./components/NavDrawer";
export default {
  name: "App",

  components: {
    NavDrawer,
  },

  data: () => ({
    //
    drawer: true,
    social: 'facebook',
  }),
  computed: {
    mobile(){
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  created(){
    setTimeout(()=> (this.mobile? this.drawer = false : '')); 
  },
  watch: {
    mobile(newValue){
    this.drawer = newValue ? false : true;
    }
  },
  methods: {
    hideDrawer(){
      if(this.mobile){
        this.drawer = false;
      }
    }
  }
};
</script>
